import "./App.css";
import Helper from "./components/HelperConsole/index";
import TopBanner from "./components/TopBanner";
import TopNav from "./components/TopNav";
import MainLogoText from "./components/MainLogoText";
import Footer from "./components/Footer";
import MediaPlayer from "./components/MediaPlayer";

function App() {
  return (
    <div className="App">
      <TopNav />
      <TopBanner />
      <div className={"page-content"}>
        <MainLogoText />
        <MediaPlayer />
        <div className={"two-col"}>
          <div className={"left flex-center"}>
            <div>
              <h1>Lilac Mall Radio</h1>
              <h1>أرجواني مول</h1>
              <h1>🌸 сирень сауда орталығы</h1>
              <h1>ライラックモール</h1>
            </div>
          </div>
          <div className={"right flex-center"}>
            <div className={"sc-widget-wrapper"}>
              <iframe
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1888903758&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"
              ></iframe>
            </div>
          </div>
        </div>
        <Helper />
        <div className={"site-counter"}>
          <img
            src="https://lilac-mall.sfo3.cdn.digitaloceanspaces.com/mall-assets/counter1.gif"
            alt="site-counter"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
