import React from "react";
import "./styles.css";

const HelperConsole = () => {
  const helperText =
    "Whats up chat!";

  return (
    <div className={"helper-wrapper"}>
      <div className={"helper-copy"}>
        <p className={"text"}>{helperText}</p>
      </div>
      <div className={"helper-icon"}>
        <img
          className={"helper-profile"}
          src={
            "https://lilac-mall.sfo3.cdn.digitaloceanspaces.com/mall-assets/helper-photo.png"
          }
          alt={"helper"}
        />
      </div>
    </div>
  );
};

export default HelperConsole;
