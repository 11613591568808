import React from "react";
import "./styles.css";

const TopBanner = () => {
  return (
    <div className={"banner-container dots"}>
      <div className={"banner-wrapper"}>
        <div className={"banner-track"}>
          <div className={"track-piece"}>MEANINGFUL CONNECTION STATUS</div>
          <div className={"track-spacer zig-zag"}/>
          <div className={"track-piece purple-background"}>ULTIMATE SOUND ENTERTAINMENT</div>
          <div className={"track-spacer checkers"}/>
          <div className={"track-piece"}>PROMO USE ONLY</div>
          <div className={"track-spacer zig-zag"}/>
          <div className={"track-piece"}>THERE ARE CATHEDRALS EVERYWHERE FOR THOSE WITH EYES TO SEE</div>
          <div className={"track-spacer checkers"}/>
          <div className={"track-piece"}>THANK YOU FOR VISITING MY PAGE</div>
          <div className={"track-spacer zig-zag"}/>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
