import React from "react";
import "./styles.css";

const TopNav = () => {
  return (
    <nav className={"top-nav"}>
      <div className={"top-nav-wrapper"}>
        <div className={"top-nav-logo"}>
          <img className={'spinning-logo'} src={"https://lilac-mall.sfo3.cdn.digitaloceanspaces.com/mall-assets/music.gif"} alt={"logo"} />
        </div>
        <div className={"top-nav-links"}>
          <a href={"/"}>Home</a>
          <a href={"/"}>About</a>
          <a href={"/"}>Contact</a>
        </div>
      </div>
    </nav>
  );
};

export default TopNav;
