import React from "react";
import "./styles.css";

const MainLogoText = () => {
  return (
    <div className={"main-logo-wrapper"}>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
      <div className="main-logo-text">Lilac Mall ™ </div>
    </div>
  );
};

export default MainLogoText;
