import React from "react";
import ReactPlayer from "react-player";
import "./styles.css";

const MediaPlayer = () => {
  const now = new Date();
  const currentDateTime = now.toLocaleString();
  return (
    <div className={"media-player-wrapper"}>
      <div className={"loader-wrapper"}>
        <div className={"loader"}></div>
      </div>
      <div className={"media-player-top-display"}>
        <div className={"status blink-me"}>Buffering...</div>
        <div className={"date-time"}>{currentDateTime}</div>
      </div>
      <ReactPlayer
        url={
          "https://lilac-mall.sfo3.cdn.digitaloceanspaces.com/mall-assets/record-loop.mp4"
        }
        controls={false}
        loop
        playing
        muted
        width={"100%"}
        height={"100%"}
      />
      <div className={"sound-cloud-iframe-wrapper"}>
        <iframe
          width="100%"
          height="20"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1906636532&color=%23ff5500&inverse=false&auto_play=false&show_user=true"
        ></iframe>
      </div>
    </div>
  );
};

export default MediaPlayer;
