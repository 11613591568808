import React from "react";
import "./styles.css";
import Instagram from "../Icons/Instagram";
import SoundCloud from "../Icons/SoundCloud";

const Footer = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "244c5959-81c8-4193-a509-98860c861e78");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <footer>
      <div className={"main-footer-wrapper"}>
        <div className={"contact-form-wrapper"}>
          <div className={"form-title"}>Contact Us</div>
          <form onSubmit={onSubmit}>
            <input type="text" name="name" placeholder={"Name"} required />
            <input type="email" name="email" placeholder={"Email"} required />
            <textarea
              name="message"
              placeholder={"Message"}
              required
            ></textarea>
            <button type="submit">Submit Form</button>
          </form>
          <span>{result}</span>
        </div>
      </div>
      <div className={"footer-options"}>
        <ul>
          <li>
            <a href={"/"}>Home</a>
          </li>
          <li>
            <a href={"/"}>About</a>
          </li>
          <li>
            <a href={"/"}>Contact</a>
          </li>
        </ul>
        <div className={"social-icons"}>
          <div className={"icon"}>
            <a
              href={"https://www.instagram.com/lilac_mall"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <Instagram />
            </a>
          </div>
          <div className={"icon"}>
            <a href={"https://soundcloud.com/lilac-mall-radio"} target={"_blank"} rel={"noreferrer"}>
              <SoundCloud />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
